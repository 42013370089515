/* eslint-disable no-undef */
import React, { PureComponent } from 'react'
import Layout from '../layout'
import { ButtonLink } from './Button'

export default class ErrorBoundary extends PureComponent {
  constructor() {
    super()
    this.state = { error: null }
  }

  componentDidCatch(error) {
    this.setState({ error })
    // Sentry.configureScope((scope) => {
    //   Object.keys(errorInfo).forEach((key) => {
    //     scope.setExtra(key, errorInfo[key])
    //   })
    // })
    // Sentry.captureException(error)
  }

  render() {
    if (this.state.error) {
      return (
        <Layout
          title="Error Page - Ashmeet Sehgal"
          seoDescription="This page contains personal information about Ashmeet Sehgal"
        >
          <div className="container">
            <h1>Oops! Something Went Wrong 🛠️ Well, This is Awkward... 😅</h1>
            <p>Looks like we tripped over a digital banana peel. 🍌</p>
            <p>
              But don’t worry, our nerds with keyboards (and snacks) are already on the case. 🧑‍💻🍕
            </p>
            <p>While we clean this mess up, you can:</p>
            <ul>
              <li>Hit refresh like it's a video game. 🎮</li>
              <li>
                Go back to the <a href="/">homepage</a>—it's much safer there! 🏠
              </li>
              <li>
                Tell us what happened (we love detective stories): Please report this to &nbsp;
                <ButtonLink
                  simpleLink
                  href="mailto:contact@ashmeetsehgal.com?&subject=ashmeetsehgal.com||ErrorOnPage&body=Please-start-writing-here"
                  title="gmail"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact@ashmeetsehgal.com
                </ButtonLink>
                .
              </li>
            </ul>
            <p>P.S. If this page were a person, it would be blushing right now. 🙈</p>
          </div>
        </Layout>
      )
    } else {
      return this.props.children
    }
  }
}
