/* eslint-disable no-shadow */
import React, { useContext, memo } from 'react'
import Helmet from 'react-helmet'
import { LazyMotion, domAnimation, m } from 'framer-motion'
import loadable from '@loadable/component'
import SEO from '../components/SEO'
import ErrorBoundary from '../components/ErrorBoundary'
import ThemeContext from '../context/ThemeContext'
import config from '../../data/SiteConfig'
import favicon from '../../static/logos/logo-48.png'
import '../styles/main.scss'
import '../styles/purgeless.scss'

const FooterComponent = loadable(() => import('../components/Footer'))

const HeaderComponent = loadable(() => import('../components/Navigation'))

const Layout = ({ children, seoImage, seoDescription, title, ...rest }) => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('ThemeContext is not provided')
  }

  const { dark, notFound } = context
  let themeClass = ''

  if (dark && !notFound) {
    themeClass = 'dark'
  } else if (notFound) {
    themeClass = 'not-found'
  }

  return (
    <ErrorBoundary>
      <Helmet
        bodyAttributes={{
          class: `theme ${themeClass}`,
        }}
        title={title || ''}
      >
        <link rel="icon" href={seoImage || favicon} />
      </Helmet>
      <SEO
        {...rest}
        isThemeDark={dark}
        seoTitle={title || ''}
        seoDescription={seoDescription || config.siteDescription}
        seoImage={seoImage || ''}
      />
      <HeaderComponent />
      <LazyMotion features={domAnimation}>
        <m.main
          initial="hidden"
          animate="visible"
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          transition={{ ease: 'easeOut', duration: 1 }}
          id="main-content"
        >
          {children}
        </m.main>
      </LazyMotion>
      <FooterComponent />
    </ErrorBoundary>
  )
}

export default memo(Layout)
